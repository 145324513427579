<template>
  <form-wizard
    id="user-report-wizard"
    @on-change="changeTab"
    shape="tab"
    color="#6c757d"
  >
    <template #title>
      &nbsp;
    </template>
    <template slot="footer" slot-scope="props">
      <hr>
      <div class="wizard-footer-left">
        <wizard-button
          v-if="props.activeTabIndex > 1 && !props.isLastStep"
          class="btn btn-primary"
          @click.native="props.prevTab();goBackToSearch()"
        >
          Indietro
        </wizard-button>
      </div>
      <div class="wizard-footer-right">
        <b-button
          variant="danger"
          @click="abortReport"
          v-if="!props.isLastStep"
          class="wizard-btn me-2"
        >
          Abbandona
        </b-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex === 1"
          @click.native="props.nextTab()"
          :class="nextButtonComputedClasses"
          class="btn btn-warning"
        >
          Prosegui
        </wizard-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex !==4 && props.activeTabIndex === 0"
          @click.native="props.nextTab()"
          class="btn btn-warning"
        >
          Accetta e Prosegui
        </wizard-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex !==4 && props.activeTabIndex > 1"
          @click.native="props.nextTab()"
          class="btn btn-warning"
        >
          Prosegui
        </wizard-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex ===4"
          @click.native="props.nextTab()"
          class="btn btn-warning"
        >
          Invia segnalazione
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep && completed"
          @click.native="onComplete"
          class="btn btn-success"
        >
          Vai all'elenco delle tue segnalazioni
        </wizard-button>
      </div>
    </template>
    <tab-content
      title="Avviso"
      icon="fas fa-hand-paper"
      class="content-invest"
    >
      <p>
        Cliccando su Prosegui, l’utente dichiara e garantisce di star effettuando una segnalazione
        riguardo alla correttezza contrattuale/finanziaria:
      </p>
      <ul>
        <li>verso un soggetto terzo libero da influenze e conflitti di interesse nei confronti dell’utente;</li>
        <li>riguardante una prestazione/transazione realmente avvenuta ed effettuata a condizioni di libero mercato.</li>
      </ul>
      <p>
        CRPagamenti si riserva di effettuare verifiche a campione sulle segnalazioni effettuate dagli utenti.
      </p>
      <p>
        L’utente accetta espressamente che il soggetto terzo potrebbe replicare alla segnalazione ricevuta.
        L’utente accetta espressamente che le segnalazioni ricevute da soggetti terzi, sia positive che negative, potranno:
      </p>
      <ul>
        <li>essere rese visibili agli altri utenti del portale;</li>
        <li>influenzare lo scoring dell’utente;</li>
        <li>influenzare la presenza dell’utente nel Club dei Virtuosi.</li>
      </ul>
      <p>
        L’utente ha il diritto, nei limiti e nei modi consentiti dal portale,
        di rispondere/obiettare pubblicamente alle valutazioni ricevute.
        <br>
        Le risposte/obiezioni espresse dall’utente potranno essere rese visibili agli utenti del portale.
      </p>
      <p>
        Il servizio di invio di segnalazioni è regolato dai Termini e Condizioni del Portale.
      </p>
    </tab-content>
    <tab-content
      title="Tipologia"
      icon="fas fa-building"
      class="content-invest"
    >
      <h5 class="text-center mb-4">
        Seleziona se la valutazione si riferisce ad una azienda o ad una persona fisica.
      </h5>
      <b-row>
        <b-col lg="8" offset-lg="2">
          <b-row>
            <b-col md="4" offset-md="2" class="mb-3 mb-md-0">
              <b-button
                size="lg"
                variant="gray"
                block
                @click="setRequestType('company')"
                :class="requestClassesCompany"
              >
                <div>
                  <font-awesome-icon icon="building"/>
                </div>
                <div class="text-center mt-3 btn-wizard--large__text">
                  AZIENDA
                </div>
              </b-button>
            </b-col>
            <b-col md="4">
              <b-button
                size="lg"
                variant="gray"
                block
                @click="setRequestType('person')"
                :class="requestClassesPerson"
              >
                <div>
                  <font-awesome-icon icon="user"/>
                </div>
                <div class="text-center mt-3 btn-wizard--large__text">
                  PERSONA FISICA
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </tab-content>
    <tab-content
      title="Generalità"
      icon="fas fa-user"
      lazy
      :before-change="validateSubject"
    >
      <div v-if="!addNewSubject">
        <h5 class="text-center mb-4">
          <span v-if="isPersonComputed">Ricerca persona</span>
          <span v-if="isCompanyComputed">Ricerca azienda</span>
        </h5>
        <b-row>
          <b-col md="8" lg="6" offset-md="2" offset-lg="3">
            <div class="mb-3">
              <v-select
                class="vs--wizard"
                placeholder="Inserire la p.iva, il codice fiscale o il nome"
                :options="options"
                @search="onSearch"
                v-model="currentSelection"
              >
                <div slot="no-options">Nessuna opzione disponibile</div>
              </v-select>
            </div>
            <div class="text-md-right">
              <b-button
                @click="createNew"
                class="btn-sm"
                variant="warning"
              >
                <span v-if="isPersonComputed">Inserisci una nuova persona</span>
                <span v-if="isCompanyComputed">Inserisci una nuova azienda</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="addNewSubject">
        <h5 class="text-center mb-4">
          <span v-if="isPersonComputed">Inserisci i dati della persona da segnalare</span>
          <span v-if="isCompanyComputed">Inserisci i dati dell'azienda da segnalare</span>
        </h5>
        <person-data
          v-if="isPersonComputed"
          :person="formReport.person"
        />
        <company-data
          v-if="isCompanyComputed"
          :company="formReport.company"
          email-mandatory
        />
      </div>
    </tab-content>
    <tab-content
      title="Tipo segnalazione"
      icon="fas fa-question-circle"
      lazy
      :before-change="validateType"
    >
      <report-context-selection :report-detail="formReport.reportDetail" />
    </tab-content>
    <tab-content
      title="Descrizione segnalazione"
      icon="fas fa-question-circle"
      lazy
      :before-change="validateQuestion"
    >
      <report-type-selection :report-detail="formReport.reportDetail" :need-description="needDescription"/>
    </tab-content>
    <tab-content
      title="Documentazione"
      icon="fas fa-file"
    >
      <div
        v-if="hasError"
        class="text-center"
      >
        <font-awesome-icon icon="exclamation-circle" class="text-danger fa-5x my-3" />
        <div class="mt-3">
          <h5>
            <strong>
              {{ errorMessage }}
            </strong>
          </h5>
        </div>
        <p class="text-underline">
          <router-link to="/">Torna alla dashboard</router-link>
        </p>
      </div>
      <report-useful-documents
        :submitted="submitted"
        :completed="completed"
        :has-error="hasError"
        entity-class="USERREPORT"
        :entity-id="reportId"
        document-entity-type="USERREPORT"
        v-else
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { debounce } from 'lodash';
import {
  checkTaxCode, isEmail, isNotEmpty, isPresent, validateVat,
} from '@/utils/validators';
import { searchCompany, searchPerson } from '@/utils/selectSearch';

const PersonData = () => import('@/components/wizards/userReportSections/PersonData.vue');
const CompanyData = () => import('@/components/wizards/userReportSections/CompanyData.vue');
const ReportTypeSelection = () => import('@/components/wizards/userReportSections/ReportTypeSelection.vue');
const ReportContextSelection = () => import('@/components/wizards/userReportSections/ReportContextSelection.vue');
const ReportUsefulDocuments = () => import('@/components/wizards/userReportSections/ReportUsefulDocuments.vue');

export default {
  name: 'UserReportWizard',
  components: {
    ReportContextSelection,
    ReportUsefulDocuments,
    // ResponseSelection,
    ReportTypeSelection,
    CompanyData,
    PersonData,
  },
  props: {
    id: String,
    requestType: String,
  },
  data() {
    return {
      needDescription: true,
      formReport: {
        person: {
          name: '',
          surname: '',
          taxCode: '',
          email: '',
        },
        company: {
          companyName: '',
          vatNumber: '',
          email: '',
        },
        reportDetail: {
          feedbackType: '',
          feedbackQuestion: '',
          feedbackDetail: '',
          note: '',
          response: '',
          reportAmount: {
            currency: 'EUR',
            amount: 0,
          },
        },
      },
      submitted: false,
      completed: false,
      hasError: false,
      errorMessage: '',
      reportCreated: null,
      hasTypeChosen: false,
      userChosenType: '',
      options: [],
      currentSelection: null,
      // addNewSubject: false,
      addNewSubject: true,
    };
  },
  mounted() {
    if (!this.isOperative) this.$router.replace('/');
    this.formReport = {
      person: {
        name: '',
        surname: '',
        taxCode: '',
        email: '',
      },
      company: {
        companyName: '',
        vatNumber: '',
        email: '',
      },
      reportDetail: {
        feedbackType: '',
        feedbackQuestion: '',
        feedbackDetail: '',
        note: '',
        response: '',
        reportAmount: {
          currency: 'EUR',
          amount: 0,
        },
      },
    };
    this.submitted = false;
    this.completed = false;
    this.hasError = false;
    this.reportCreated = null;
    this.hasTypeChosen = false;
    this.userChosenType = '';
    this.options = [];
    this.currentSelection = null;
    // this.addNewSubject = false;
    this.addNewSubject = true;
  },
  computed: {
    isCompany() {
      return this.userChosenType === 'company';
    },
    isPerson() {
      return this.userChosenType === 'person';
    },
    hasRequestTypePreselected() {
      return this.isCompany || this.isPerson;
    },
    reportId() {
      return this.reportCreated || '';
    },
    isCompanyComputed() {
      // return this.isCompany || this.userChosenType === ' COMPANY';
      return this.isCompany;
    },
    isPersonComputed() {
      // return this.isPerson || this.userChosenType === ' PERSON';
      return this.isPerson;
    },
    baseButtonClasses() {
      return 'btn-wizard--large';
    },
    requestClassesCompany() {
      if (this.userChosenType === 'company') return `${this.baseButtonClasses} active`;
      return this.baseButtonClasses;
    },
    requestClassesPerson() {
      if (this.userChosenType === 'person') return `${this.baseButtonClasses} active`;
      return this.baseButtonClasses;
    },
    hasNoResultFound() {
      return false;
    },
    nextButtonComputedClasses() {
      if (this.userChosenType === '') {
        return 'disabled';
      }
      return '';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  methods: {
    onComplete() {
      this.$router.push('/user-report-history');
    },
    abortReport() {
      this.$confirm(
        {
          message: 'Sei sicuro di voler annullare la creazione della segnalazione?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$router.replace('/dashboard-utente');
            }
          },
        },
      );
    },
    goBackToSearch() {
      this.currentSelection = {};
      // this.addNewSubject = false;
    },
    createNew() {
      this.currentSelection = {};
      this.addNewSubject = true;
    },
    validateSubject() {
      // if (!this.currentSelection) {
      //   return false;
      // }
      /*
      if (this.isPersonComputed) {
        const {
          name, surname, taxCode, email,
        } = this.formReport.person;
        return isPresent(name) && isPresent(surname) && checkTaxCode(taxCode) && isEmail(email);
      }
       */
      if (this.isCompanyComputed) {
        if (this.currentSelection?.company) {
          const {
            vatNumber,
          } = this.currentSelection.company.info;
          this.formReport.company.companyName = vatNumber;
          this.formReport.company.vatNumber = vatNumber;
        } else {
          this.formReport.company.companyName = this.formReport.company.vatNumber;
        }
        const {
          vatNumber,
          email,
        } = this.formReport.company;

        return validateVat(vatNumber) && isEmail(email); // && isPresent(companyName) && isEmail(email);
      }
      if (this.isPersonComputed) {
        if (this.currentSelection?.person) {
          const {
            personInfo, anagraficalInfo,
          } = this.currentSelection.person;
          this.formReport.person.name = personInfo.name;
          this.formReport.person.surname = personInfo.surname;
          this.formReport.person.taxCode = anagraficalInfo.taxCode;
        }
        const {
          name, surname, taxCode,
        } = this.formReport.person;

        return isPresent(name) && isPresent(surname) && checkTaxCode(taxCode); // && isEmail(email);
      }
      return false;
    },
    validateType() {
      const { feedbackType } = this.formReport.reportDetail;
      return isPresent(feedbackType);
    },
    validateQuestion() {
      const {
        feedbackType, feedbackQuestion, feedbackDetail, reportAmount,
      } = this.formReport.reportDetail;
      const type = this.$store.getters['tableUtils/getUserReportType'].find((item) => item.key === feedbackType);
      let amountValid = isNotEmpty(type);
      if (type?.additionalInfos?.hasAmount) {
        amountValid = reportAmount.amount > 0;
      }
      console.log('validate: ', feedbackType, feedbackQuestion, feedbackDetail, reportAmount, amountValid);
      return isPresent(feedbackType) && isPresent(feedbackQuestion) && (isPresent(feedbackDetail) || this.needDescription) && amountValid;
    },
    changeTab(oldIndex, newIndex) {
      // console.log('newIndex', newIndex);
      if (newIndex === 5) {
      // if (newIndex === 4) {
        const { person, company, reportDetail } = this.formReport;
        const subject = this.isPersonComputed ? person : company;
        const currentProfile = this.$store.getters['subject/currentCompany'];
        let reporterType;
        let reporterId;

        if (isNotEmpty(currentProfile)) {
          reporterType = 'COMPANY';
          reporterId = currentProfile.companyId;
        } else {
          reporterType = 'PERSON';
          reporterId = this.$store.getters['subject/currentPerson'].personId;
        }

        const reportRequest = {
          ...subject,
          ...reportDetail,
          reporterType,
          reporterId,
        };

        this.completed = false;
        this.submitted = true;
        this.$store.dispatch('score/createUserReport', reportRequest)
          .then((reportCreated) => {
            this.submitted = false;

            const { error, data } = reportCreated;
            if (error) {
              console.log('error data', data);
              if (data.errors.find((item) => item.code === 'owner')) {
                this.errorMessage = 'Non è possibile aprire segnalazioni su se stessi oppure su aziende collegate';
              } else {
                this.errorMessage = 'Non è possibile completare la creazione della segnalazione';
              }
              this.hasError = true;
            } else {
              this.reportCreated = data;
              this.completed = true;

              this.$store.dispatch('score/getEntityScore', {
                entityType: reporterType.toLowerCase(),
                entityId: reporterId.toLowerCase(),
              });
            }
          },
          (error) => {
            console.error('Errore in creazione segnalazione', error);
            this.completed = false;
            this.submitted = false;
          }).catch((error) => {
            console.error('Errore ricevuto in creazione segnalazione', error);
            this.completed = false;
            this.submitted = false;
          });
      }
    },
    setRequestType(val) {
      // console.log('changing type:', val);
      this.userChosenType = val;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      let request = null;
      if (vm.isCompany) {
        request = searchCompany(search);
      } else if (vm.isPerson) {
        request = searchPerson(search);
      }
      if (!request) {
        // eslint-disable-next-line no-param-reassign
        vm.options = [];
        return;
      }
      request.then((res) => {
        console.log('res', res);
        // eslint-disable-next-line no-param-reassign
        vm.options = res;
        loading(false);
      }).catch((err) => {
        console.log('error search ', vm.userChosenType, err);
        loading(false);
      });
    }, 350),
  },
};
</script>

<style scoped>

</style>
